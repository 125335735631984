import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Space,
  Spin,
} from "antd";
import React from "react";
import api from "../../URL/axiosConfig";

const ModalSetInterval = ({ open, setOpen, devEui, getData }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [form] = Form.useForm();

  const onFinish = values => {
    console.log("Success:", values);
    setLoading(true);
    api({
      url: `/action/interval/cms/${values.DevEui}/${values.Interval}`,
      method: "POST",
    })
      .then(ok => {
        setLoading(false);
        getData();
        setOpen(false);
        // alert("Success Set Interval");
        notification.success({
          message: "Success set interval",
          placement: "bottomRight",
        });
      })
      .catch(err => {
        // alert(err.response.data.message);
        notification.success({
          message: err.response.data.message,
          placement: "bottomRight",
        });
        setLoading(false);
      });
  };

  React.useEffect(() => {
    console.log(devEui, "DEVEUI");
    if (open) {
      form.setFields([
        {
          name: "DevEui",
          value: devEui,
        },
      ]);
    } else {
      form.resetFields();
    }
  }, [open, devEui]);

  return (
    <Modal
      cancelButtonProps={{
        // size: "large",
        style: {
          display: "none",
        },
      }}
      visible={open}
      onCancel={() => setOpen(false)}
      okButtonProps={{ style: { display: "none" } }}
      title="Set Interval"
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          name="setinterval"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item label="DevEui" name="DevEui">
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Interval (minutes)"
            name="Interval"
            rules={[
              {
                required: true,
                message: "Please input your Interval!",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Space>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalSetInterval;
