import {
  Backdrop,
  Button,
  ButtonGroup,
  Fade,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React from "react";
import api from "../../URL/axiosConfig";
import Failed from "../message/Failed";
import ReactLoading from "react-loading";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "40%",
    maxHeight: "500px",
    overflow: "scroll",
  },
  input: {
    display: "none",
  },
}));

const ImportCSV = ({ modal, setModal = () => 0, getData }) => {
  const classes = useStyles();
  const [file, setFile] = React.useState(null);
  const [notif, setNotif] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    console.log(file, "FAILLEE");
    if (file) {
      const formData = new FormData();
      setLoading(true);
      formData.append("csv", file);
      api({
        method: "POST",
        url: "/action/csv/to/json",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then(ok => {
          setLoading(false);
          console.log(ok.data, "DATAA");
          setData(ok.data.data);
        })
        .catch(err => {
          setLoading(false);

          console.log(err);
          //   Failed({
          //     text: err.response.data.message,
          //     open: notif,
          //     setOpen: setNotif,
          //     type: "error",
          //   });
          alert(err.response.data.message);
        });
    }
  }, [file]);

  const handleSubmit = () => {
    setLoading(true);
    api({
      method: "POST",
      url: "/action/json/to/db",
      data: data,
    })
      .then(ok => {
        setLoading(false);
        setModal(false);
        getData();
        alert(ok.data.message);
      })
      .catch(err => {
        setLoading(false);
        alert(err.response.data.message);
      });
  };

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.modal}
      open={modal}
      onClose={setModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modal}>
        <div className={classes.paper2}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            <Typography variant="h6">Import CSV</Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  //   flexWrap: "wrap",
                  justifyContent: "space-between",
                  width: "90%",
                  paddingTop: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {loading ? (
                    <ReactLoading type={"spin"} color={"#438838"} />
                  ) : (
                    <div>
                      <input
                        accept=".csv"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        multiple
                        onChange={e =>
                          e.target.files[0] && setFile(e.target.files[0])
                        }
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          Upload
                        </Button>
                      </label>
                    </div>
                  )}
                </div>
                <MUIDataTable
                  data={data}
                  columns={[
                    "typeId",
                    "application_name",
                    "devEUI",
                    "middlewareId",
                  ]}
                  options={{
                    filter: false,
                    selectableRows: false,
                    filterType: "dropdown",
                    responsive: "stacked",
                    print: false,
                    download: false,
                    viewColumns: false,
                  }}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {loading ? (
                    <ReactLoading type={"spin"} color={"#438838"} />
                  ) : (
                    <ButtonGroup variant="contained">
                      <Button
                        variant="contained"
                        component="span"
                        onClick={() => setModal(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </ButtonGroup>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ImportCSV;
