import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TextField } from "@material-ui/core";
import api from "../../URL/axiosConfig";

const MasterCustomer = ({ style, onChange, valueData, typeId = 4 }) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loadingSearch, setLoadingSearch] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState(undefined);
  //   const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    // setLoadingSearch(true);

    api({
      url: "/list/node",
      params: {
        typeId,
        search: searchValue,
      },
    })
      .then(ok => {
        setLoadingSearch(false);
        // console.log(ok.data, "OK");
        ok.data.messages.map((val, idx) => {
          ok.data.messages[idx].name = val.devEUI;
        });
        setOptions(ok.data.messages);
      })
      .catch(err => {
        setLoadingSearch(false);
        // message.info("error getting data master");
      });
  }, [searchValue]);

  React.useEffect(() => {
    // if (!open) {
    //   setOptions([]);
    // }
  }, [open]);

  //   React.useEffect(() => {
  //     if (valueData && options.length > 0) {
  //       //   setTimeout(() => {
  //       let arr = [];
  //       options.map((val, idx) => {
  //         if (val.id == valueData) {
  //           arr.push(val);
  //         }
  //       });
  //       setData(arr[0]);
  //       //   }, 100);
  //     }
  //   }, [valueData, options]);

  //   console.log(valueData, "VALES");

  //   console.log(data?.name, "name");

  return (
    <Autocomplete
      //   id="asynchronous"
      style={style}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => option.name}
      options={options}
      onChange={onChange}
      loading={loadingSearch}
      //   value={{ name: valueData }}
      defaultValue={{ name: valueData }}
      //   defaultValue={options.find((v) => v.id == valueData)}
      //   value={data?.name}
      //   inputValue={data?.name}
      onInputChange={(e, value) => {
        console.log(value);
        setSearchValue(value);
      }}
      renderInput={params => (
        <TextField
          {...params}
          label="DevEui"
          variant="outlined"
          //   onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loadingSearch ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default MasterCustomer;
