import React from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Spin,
  Table as TableAntd,
} from "antd";
import api from "../../URL/axiosConfig";
import Column from "antd/lib/table/Column";
import * as XLSX from "xlsx"; // Import the xlsx library

const ModalJoin = ({ open, setOpen, getData, devEUI }) => {
  const [data2, setData2] = React.useState([]);
  const [header, setHeader] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [showExportButton, setShowExportButton] = React.useState(false); // State for showing the export button

  const onFinish = values => {
    console.log("Success:", values);
  };

  // Function to export data to Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data2);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    XLSX.writeFile(workbook, "exported_data.xlsx");
  };

  React.useEffect(() => {
    setLoading(true);
    if (open) {
      let headers = [];
      let data = [];
      api({
        url: `/v2/deyu/join/req/${devEUI}`,
        method: "GET",
      })
        .then(ok => {
          data = ok.data;
          if (data.length > 0) {
            let idxs = 0;

            data.map((val, idx) => {
              if (val.length > data[0].length) {
                idxs = idx;
              }
            });

            data[idxs].map((val, idx) => {
              headers.push(val.name);
            });

            setHeader(headers);

            data.map((val, idx) => {
              val.map((val2, idx2) => {
                data[idx][val2.name] = val2.value;
              });
            });

            function swapNameAndTime(arr) {
              // Mencari indeks 'type' dan 'time'
              const typeIndex = arr.findIndex(item => item.name === "type");
              const timeIndex = arr.findIndex(item => item.name === "time");

              if (typeIndex !== -1 && timeIndex !== -1) {
                // Menukar elemen pada indeks type dan time
                const temp = arr[typeIndex];
                arr[typeIndex] = arr[timeIndex];
                arr[timeIndex] = temp;
              }
            }
            setData2(data);
            setShowExportButton(true); // Show the export button
          }
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [open, devEUI]);

  return (
    <Modal
      cancelButtonProps={{
        style: {
          display: "none",
        },
      }}
      width={1000}
      visible={open}
      onCancel={() => setOpen(false)}
      okButtonProps={{ style: { display: "none" } }}
      title="Join Request"
    >
      <Spin spinning={loading}>
        {showExportButton && ( // Show the export button when data is available
          <Button
            type="primary"
            onClick={exportToExcel}
            style={{ marginTop: "16px" }}
          >
            Export to Excel
          </Button>
        )}
        <TableAntd dataSource={data2} scroll={{ x: true }}>
          {header.map((val, idx) => {
            return (
              <Column title={val} align="center" dataIndex={val} key={idx} />
            );
          })}
        </TableAntd>
      </Spin>
    </Modal>
  );
};

export default ModalJoin;
