import React, { useState } from "react";
import { Grid, Typography, Button, TextField, Fade } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { URL } from "../../URL/index";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// styles
import useStyles from "./styles";

// logo
import logo from "../../assets/weiots.png";

// context
import { useUserDispatch } from "../../context/UserContext";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [error, setError] = useState(null);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  const handleLogin = e => {
    e.preventDefault();
    setError(false);
    console.log(loginValue, passwordValue);
    axios({
      method: "POST",
      url: URL + "/login",
      data: {
        email: loginValue,
        password: passwordValue,
      },
    })
      .then(res => {
        console.log(res.data);
        if (res.data.responseCode === 200) {
          localStorage.setItem("token_weiots_cms", res.data.token);
          userDispatch({ type: "LOGIN_SUCCESS" });
          window.location.replace("#/app/company");
        } else {
          alert("Ada kesalahan jaringan");
        }
      })
      .catch(e => {
        alert(e.response.data.messages);
        console.log(e.response);
      });
  };
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Typography variant="h1" className={classes.greeting}>
            Login
          </Typography>
          <Fade in={error}>
            <Typography color="secondary" className={classes.errorMessage}>
              Something is wrong with your login or password :(
            </Typography>
          </Fade>
          <TextField
            id="email"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={loginValue}
            onChange={e => setLoginValue(e.target.value)}
            margin="normal"
            placeholder="Email Adress"
            type="email"
            fullWidth
          />
          <TextField
            id="password"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={passwordValue}
            onChange={e => setPasswordValue(e.target.value)}
            margin="normal"
            placeholder="Password"
            type="password"
            fullWidth
          />
          <div className={classes.formButtons}>
            <Button
              onClick={handleLogin}
              variant="contained"
              color="primary"
              size="large"
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
