// First we need to import axios.js
import axios from "axios";
import { URL_IKI } from "./index";
// Next we make an 'instance' of it
const antares = axios.create({
  // .. where we make our configurations
  baseURL: URL_IKI,
});

// Where you would set stuff like your 'Authorization' header, etc ...
antares.defaults.headers.common["Authorization"] = localStorage.getItem(
  "token_weiots_cms",
);

// // Also add/ configure interceptors && all the other cool stuff

// instance.interceptors.request...

export default antares;
