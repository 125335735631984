import React from "react";
import {
  Button,
  makeStyles,
  CircularProgress,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import GoogleMapReact from "google-map-react";
import { green } from "@material-ui/core/colors";
import api from "../../URL/axiosConfig";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const EditGateway = ({ onclose, data }) => {
  const classes = useStyles();

  const [GatewayName, setGatewayName] = React.useState("");
  const [GatewayId, setGatewayId] = React.useState("");
  const [Status, setStatus] = React.useState("Active");
  const [Location, setLocation] = React.useState("");

  const [types, setTypes] = React.useState([]);
  const [middlewares, setMiddlewares] = React.useState([]);
  // const [appName, setAppname] = React.useState("");
  // const [deveui, setDeveui] = React.useState("");
  // const [deveuiDisplay, setDeveuiDisplay] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [middlewareId, setMiddleware] = React.useState("");
  const [check, setCheck] = React.useState(false);
  // const [typeId, setType] = React.useState("");
  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  //const [saveDisable, setSaveDisable] = React.useState(true);
  const inputLabel = React.useRef(null);
  const labelWidth = inputLabel.current ? inputLabel.current.clientWidth : 0;
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const [center, setCenter] = React.useState({
    lat: -6.201058,
    lng: 106.8226953,
  });
  const [zoom, setZoom] = React.useState(11);
  const [draggable, setDraggable] = React.useState(true);
  const [lat, setLat] = React.useState(
    data.latitude ? parseFloat(data.latitude) : -0.201058,
  );
  const [lng, setLng] = React.useState(
    data.longitude ? parseFloat(data.longitude) : 106.8226953,
  );
  const [type, setType] = React.useState("");

  // React.useEffect(() => {
  //   //setLabelWidth(inputLabel.current.offsetWidth);
  //   if (appName == "" || deveui == "" || middlewareId == "" || typeId == "") {
  //     setSaveDisable(true);
  //   } else setSaveDisable(false);
  // }, [appName, deveui, middlewareId, typeId]);
  React.useEffect(() => {
    setMiddleware(data.middlewareId);
    setGatewayId(data.gateway_id);
    setGatewayName(data.gateway_name);
    setStatus(data.status ? data.status : "Active");
    setLocation(data.location);
    setType(data.type);
  }, [data]);

  React.useEffect(() => {
    const fetchType = async () => {
      const res = await api("/list/type");
      console.log("data", res.data);
      const data = res.data.list_type ? res.data.list_type : [];
      setTypes(data);
    };
    const fetchMw = async () => {
      const res = await api("/list/middleware/v2");
      console.log("data", res.data);
      const data = res.data.messages ? res.data.messages : [];
      setMiddlewares(data);
    };
    fetchType();
    fetchMw();
    // setLabelWidth(inputLabel.current.offsetWidth);
    // setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const checkGateway = e => {
    e.preventDefault();
    // setCheck(false);
    setLoading(true);
    setCheck(false);
    setLoading(false);
    // api({
    //   method: "POST",
    //   url: "/check/gateway",
    //   data: {
    //     gatewayId: GatewayId.replace(/\s/g, "").trim(),
    //   },
    // })
    //   .then(res => {
    //     setCheck(false);
    //     setLoading(false);
    //     alert("Gateway Available");
    //   })
    //   .catch(e => {
    //     setCheck(true);
    //     setLoading(false);
    //     console.log(e.response.data);
    //     alert("Gateway Offline or Not Found");
    //   });
  };

  const addCompany = e => {
    e.preventDefault();
    api({
      method: "PATCH",
      url: `/gateway/${data.id}`,
      data: {
        gateway_name: GatewayName,
        gateway_id: GatewayId.replace(/\s/g, "").trim(),
        // status: Status,
        // location: Location,
        // installation_date: moment(),
        // longitude: lng,
        // latitude: lat,
        middlewareId: middlewareId,
        type: type,
      },
    })
      .then(res => {
        console.log(res.data);
        if (res.data.responseCode === 200) {
          alert(res.data.messages);
          window.location.reload();
        } else {
          alert(res.data.messages);
        }
      })
      .catch(e => {
        alert(e.response.data.messages);
      });
  };

  const onCircleInteraction = (childKey, childProps, mouse) => {
    // function is just a stub to test callbacks
    setDraggable(false);
    setLat(mouse.lat);
    setLng(mouse.lng);

    console.log("onCircleInteraction called with", childKey, childProps, mouse);
  };

  const onCircleInteraction3 = (childKey, childProps, mouse) => {
    setDraggable(true);
    // function is just a stub to test callbacks
    console.log("onCircleInteraction called with", childKey, childProps, mouse);
  };
  const _onChange = (center, zoom) => {
    setCenter({ lat: center.lat, lng: center.lng });
    setZoom(zoom);
  };
  const handleChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value,
    });
    console.log(state);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      <Typography variant="h6">Edit Gateway</Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "",
            flexWrap: "wrap",
            justifyContent: "space-around",
            width: "90%",
          }}
        >
          {/* <FormControl
            style={{ marginTop: "5%", width: "100%" }}
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              Middleware
            </InputLabel>
            <Select
              required
              error={middlewareId == "" ? true : false}
              native
              value={middlewareId}
              onChange={e => setMiddleware(e.target.value)}
              labelWidth={labelWidth}
            >
              <option value="" />
              {middlewares.map((val, index) => {
                return (
                  <option key={index} value={val.id}>
                    {val.name}
                  </option>
                );
              })}
            </Select>
          </FormControl> */}
          {/* value={deveui.toString().replace(/\d{4}(?=.)/g, '$&-')} */}
          <div style={{ height: "30vh", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCGWVDerQeMfvPv81kpwvLKirgZSdOt3Lk",
                libraries: ["places"],
              }}
              draggable={draggable}
              defaultCenter={{ lat: lat, lng: lng }}
              defaultZoom={zoom}
              //onChange={_onChange}

              // onChildMouseDown={onCircleInteraction}
              // onChildMouseUp={onCircleInteraction3}
              // onChildMouseMove={onCircleInteraction}
              onChildClick={() => console.log("child click")}
              onClick={() => console.log("mapClick")}
            >
              <div className="place" lat={lat} lng={lng}>
                <RoomIcon style={{ color: "red" }} />
              </div>
            </GoogleMapReact>
          </div>

          <FormControl
            style={{ marginTop: "5%", width: "100%" }}
            size="small"
            variant="outlined"
          >
            <InputLabel shrink ref={inputLabel} htmlFor="outlined-middleware">
              Middleware
            </InputLabel>
            <Select
              required
              error={middlewareId == "" ? true : false}
              native
              id="outlined-middleware"
              inputProps={{
                id: "outlined-middleware",
              }}
              value={middlewareId}
              onChange={e => setMiddleware(e.target.value)}
              labelWidth={labelWidth}
            >
              <option value="" />
              {middlewares.map((val, index) => {
                return (
                  <option key={index} value={val.id.toString()}>
                    {val.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            required
            error={GatewayId == "" ? true : false}
            onChange={e =>
              setGatewayId(
                e.target.value
                  .replace(/\s/g, "")
                  .replace(/(.{4})/g, "$1 ")
                  .trim(),
              )
            }
            value={GatewayId}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Gateway Id"
          />
          <TextField
            required
            error={GatewayName == "" ? true : false}
            onChange={e => setGatewayName(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Gateway Name"
            value={GatewayName}
          />
          <FormControl
            style={{ marginTop: "5%", width: "100%" }}
            size="small"
            variant="outlined"
            disabled
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              Status
            </InputLabel>
            <Select
              required
              error={Status == "" ? true : false}
              native
              value={Status}
              onChange={e => setStatus(e.target.value)}
              labelWidth={labelWidth}
              disabled
            >
              <option value={"Active"}>Active</option>
              <option value={"Non Active"}>Non Active</option>
            </Select>
          </FormControl>
          {/* <TextField
            required
            error={Status == "" ? true : false}
            onChange={e => setStatus(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Status"
          /> */}
          <TextField
            required
            disabled
            error={Location == "" ? true : false}
            onChange={e => setLocation(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Location"
            value={Location}
          />
          <TextField
            onChange={e => setType(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Type"
            value={type}
          />
          <div
            id="row"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <TextField
              // required
              disabled
              //error={Latitude == "" ? true : false}
              // onChange={e => setLocation(e.target.value)}
              value={lat}
              style={{ marginTop: "5%", width: "48%" }}
              variant="outlined"
              size="small"
              label="Latitude"
            />
            <TextField
              disabled
              // required
              //error={Longitude == "" ? true : false}
              // onChange={e => setLocation(e.target.value)}
              value={lng}
              style={{ marginTop: "5%", width: "48%" }}
              variant="outlined"
              size="small"
              label="Longitude"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "5%",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={onclose}
          variant="outlined"
          size="small"
          color="default"
        >
          cancel
        </Button>
        <Button
          //disabled={saveDisable}
          onClick={addCompany}
          variant="outlined"
          size="small"
          style={{
            display: check == true ? "none" : "flex",
            marginLeft: "3%",
            backgroundColor: "#429C46",
            color: "white",
          }}
        >
          save
        </Button>

        <div
          style={{
            display: check == true ? "flex" : "none",
            marginLeft: "3%",
            backgroundColor: "#429C46",
            color: "white",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={checkGateway}
          >
            check
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditGateway;
