import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Space,
  Spin,
} from "antd";
import React from "react";
import api from "../../URL/axiosConfig";

const ModalDetailLocation = ({ open, setOpen, getData, node_id }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [form] = Form.useForm();

  const onFinish = values => {
    console.log("Success:", values);
  };

  React.useEffect(() => {
    setLoading(true);
    if (open) {
      api({
        url: `/v1/check/detail/location/${node_id}`,
        method: "GET",
      })
        .then(ok => {
          console.log(ok.data);
          form.setFields([
            {
              name: "company_name",
              value: ok.data.data.company_name,
            },
            {
              name: "area_name",
              value: ok.data.data.area_name,
            },
            {
              name: "internal_name",
              value: ok.data.data.internal_name,
            },
            {
              name: "tenant_name",
              value: ok.data.data.tenant_name,
            },
          ]);
          setLoading(false);
        })
        .catch(err => {
          console.log(err.message);
          form.setFields([
            {
              name: "company_name",
              value: "N/A",
            },
            {
              name: "area_name",
              value: "N/A",
            },
            {
              name: "internal_name",
              value: "N/A",
            },
            {
              name: "tenant_name",
              value: "N/A",
            },
          ]);
          setLoading(false);
        });
    } else {
      form.resetFields();
      setLoading(false);
    }
  }, [open, node_id]);

  return (
    <Modal
      cancelButtonProps={{
        // size: "large",
        style: {
          display: "none",
        },
      }}
      visible={open}
      onCancel={() => setOpen(false)}
      okButtonProps={{ style: { display: "none" } }}
      title="Detail Location"
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          name="settopup"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item label="Company Name" name="company_name">
            <Input disabled style={{ color: "#000" }} />
          </Form.Item>
          <Form.Item label="Area Name" name="area_name">
            <Input disabled style={{ color: "#000" }} />
          </Form.Item>
          <Form.Item label="Internal Name" name="internal_name">
            <Input disabled style={{ color: "#000" }} />
          </Form.Item>
          <Form.Item label="Tenant Name" name="tenant_name">
            <Input disabled style={{ color: "#000" }} />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Space>
              <Button onClick={() => setOpen(false)}>Close</Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalDetailLocation;
