import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Space,
  Spin,
} from "antd";
import React from "react";
import api from "../../URL/axiosConfig";

const ModalSetTopup = ({ open, setOpen, devEui, getData }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [form] = Form.useForm();

  const onFinish = values => {
    console.log("Success:", values);
    setLoading(true);
    api({
      url: `/action/topup/cms/${values.DevEui}/${values.Topup}`,
      method: "POST",
    })
      .then(ok => {
        setLoading(false);
        getData();
        setOpen(false);
        // alert("Success Set Interval");
        notification.success({
          message: "Success set Topup",
          placement: "bottomRight",
        });
      })
      .catch(err => {
        // alert(err.response.data.message);
        notification.success({
          message: err.response.data.message,
          placement: "bottomRight",
        });
        setLoading(false);
      });
  };

  React.useEffect(() => {
    console.log(devEui, "DEVEUI");
    setLoading(true);
    if (open) {
      api({
        url: `/action/signal/power/${devEui}`,
        method: "GET",
      })
        .then(ok => {
          console.log(ok.data);
          form.setFields([
            {
              name: "uplink_rssi",
              value: ok.data.data.uplink_rssi,
            },
            {
              name: "uplink_snr",
              value: ok.data.data.uplink_snr,
            },
            {
              name: "downlink_rssi",
              value: ok.data.data.downlink_rssi,
            },
            {
              name: "downlink_snr",
              value: ok.data.data.downlink_snr,
            },
          ]);
          setLoading(false);
        })
        .catch(err => {
          console.log(err.message);
          form.setFields([
            {
              name: "uplink_rssi",
              value: "N/A",
            },
            {
              name: "uplink_snr",
              value: "N/A",
            },
            {
              name: "downlink_rssi",
              value: "N/A",
            },
            {
              name: "downlink_snr",
              value: "N/A",
            },
          ]);
          setLoading(false);
        });
    } else {
      form.resetFields();
      setLoading(false);
    }
  }, [open, devEui]);

  return (
    <Modal
      cancelButtonProps={{
        // size: "large",
        style: {
          display: "none",
        },
      }}
      visible={open}
      onCancel={() => setOpen(false)}
      okButtonProps={{ style: { display: "none" } }}
      title="Show Signal"
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          name="settopup"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item label="Uplink Rssi" name="uplink_rssi">
            <Input disabled style={{ color: "#000" }} />
          </Form.Item>
          <Form.Item label="Uplink Snr" name="uplink_snr">
            <Input disabled style={{ color: "#000" }} />
          </Form.Item>
          <Form.Item label="Downlink Rssi" name="downlink_rssi">
            <Input disabled style={{ color: "#000" }} />
          </Form.Item>
          <Form.Item label="Downlink Snr" name="downlink_snr">
            <Input disabled style={{ color: "#000" }} />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Space>
              <Button onClick={() => setOpen(false)}>Close</Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalSetTopup;
