import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import AddMiddleware from "./AddMiddleware";

import { Grid, Modal, Backdrop, Fade, makeStyles, } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import api from "../../URL/axiosConfig";
// components
import PageTitle from "../../components/PageTitle";


const useStyles = makeStyles(theme => ({
  formControl: {
      margin: theme.spacing(1),
      minWidth: 120
  },
  selectEmpty: {
      marginTop: theme.spacing(2)
  },
  modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
  },
  paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '80%'
  },
  paper2: {
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '40%'
  }
}));
function Middleware(props) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [head, setHead] = React.useState([]);
  React.useEffect(() => {
      const fetchData = async () => {
          const res = await api("/list/middleware");
          console.log("data", res.data)
          const datas = res.data.messages ? res.data.messages : [];
          console.log(datas)
          setData(datas);
          var headItems = [];
          if (datas.length !== 0) {
          Object.keys(datas[0]).forEach(function (key) {
              headItems.push(key);
          });
        }
          setHead(headItems);
      };
      fetchData();

  }, []);
  const classes = useStyles();
  const handleModal = () => {
      setOpen(!open);
      console.log(open);
  };
    return (
        <>
          <PageTitle title="Middleware" button="Add Middleware" modal={handleModal}/>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <MUIDataTable
                        data={data}
                        columns={head}
                    options={{
                        filter: true,
                        selectableRows: true,
                        filterType: "dropdown",
                        responsive: "stacked",
                        rowsPerPage: 10,
                    }}
              />
            </Grid>
          </Grid>
          <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper2}>
                        <AddMiddleware onclose={handleModal} />
                    </div>
                </Fade>
            </Modal>
        </>
      );
}

export default withRouter(Middleware);