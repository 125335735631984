import { Modal, Spin } from "antd";
import React from "react";
import api from "../../URL/axiosConfig";
import ReactJson from "react-json-view";

const ModalLogLastNode = ({ open, setOpen, node_id }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      api({
        url: "/action/last/log/" + node_id,
      })
        .then(ok => {
          setLoading(false);
          console.log(ok.data, "DATAA");
          if (ok.data.data.payload) {
            setData(JSON.parse(ok.data.data.payload));
          }
        })
        .catch(err => {
          // setLoading(false)
        });
    } else {
      setData({});
    }
  }, [open]);

  return (
    <Modal
      cancelButtonProps={{
        size: "large",
      }}
      visible={open}
      onCancel={() => setOpen(false)}
      okButtonProps={{ style: { display: "none" } }}
      title="Logs Last Node"
    >
      <Spin spinning={loading}>
        <ReactJson src={data} displayDataTypes={false} />
      </Spin>
    </Modal>
  );
};

export default ModalLogLastNode;
