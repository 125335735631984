import React from "react";
import {
  Button,
  makeStyles,
  CircularProgress,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import api from "../../URL/axiosConfig";
import antares from "../../URL/axiosConfigAntares";
import apiIki from "../../URL/axiosConfigIki";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Cards = props => {
  const classes = useStyles();
  const [CompanyList, setCompanyList] = React.useState([]);
  const [companyId, setcompanyId] = React.useState("");
  const [saveDisable, setSaveDisable] = React.useState(true);
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
    if (companyId != "") {
      setSaveDisable(false);
    } else setSaveDisable(true);
  }, [companyId]);

  React.useEffect(() => {
    const fetchMw = async () => {
      const res = await api("/list/company");
      const data = res.data.list_company ? res.data.list_company : [];
      setCompanyList(data);
    };
    fetchMw();
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const addCompany = e => {
    e.preventDefault();
    api({
      method: "patch",
      url: "/assigned/node",
      data: { companyId: companyId, node_id: props.idnode },
    })
      .then(res => {
        alert("Sukses Assigned Node");
        window.location.reload();
      })
      .catch(e => {
        alert(e.message);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      <Typography variant="h6">Assigned Node</Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "",
            flexWrap: "wrap",
            justifyContent: "space-around",
            width: "90%",
          }}
        >
          <FormControl
            style={{ marginTop: "5%", width: "100%" }}
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              Company Name
            </InputLabel>
            <Select
              required
              error={companyId == "" ? true : false}
              native
              value={companyId}
              onChange={e => setcompanyId(e.target.value)}
              labelWidth={labelWidth}
            >
              <option value="" />
              {CompanyList.map((val, index) => {
                return (
                  <option key={index} value={val.id}>
                    {val.company_name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </div>
      </div>
      <div
        style={{
          marginTop: "5%",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={props.onclose}
          variant="outlined"
          size="medium"
          color="default"
        >
          cancel
        </Button>
        <Button
          disabled={saveDisable}
          onClick={addCompany}
          variant="outlined"
          size="medium"
          style={{
            marginLeft: "3%",
            backgroundColor: "#429C46",
            color: "white",
          }}
        >
          save
        </Button>
      </div>
    </div>
  );
};

export default Cards;
