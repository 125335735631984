// const URL = "http://18.140.193.173:3000/api";
const URL = "https://api.weiots.wiraenergi.co.id/api";
// const URL_ANTARES = "http://18.140.193.173:3006/api";
const URL_ANTARES = "https://api.weiots.wiraenergi.co.id/antares";

const URL_IKI = "https://api.weiots.wiraenergi.co.id/iki";
// const URL = "http://localhost:3002/api";
const LOCAL_URL = "http://localhost:3000";

export { URL, LOCAL_URL, URL_ANTARES, URL_IKI };
