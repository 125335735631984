import React from "react";
import { Button, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import api from "../../URL/axiosConfig";

const Cards = (props) => {
  const [name, setName] = React.useState('')
  const [urlLink, setUrllink] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [method, setMethod] = React.useState('')
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const addMiddleware = e => {
    e.preventDefault();
    api({
      method: "POST",
      url: "/add/middleware",
      data: {
        name: name,
        url_link: urlLink,
        username: username,
        password: password,
        method: method,
      }
    })
      .then(res => {
        console.log(res.data);
        if (res.data.responseCode === 200) {
          alert(res.data.messages)
          props.history.goBack()
        } else {
          alert(res.data.messages)
        }
      })
      .catch(e => {
        console.log(e);
      });

  }
  //console.log(method)
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-around' }}>
      <Typography variant='h6'>Add Middleware</Typography>

      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around' }}>
        <div style={{ display: 'flex', flexDirection: '', flexWrap: 'wrap', justifyContent: 'space-around', width: '80%' }}>
          <TextField onChange={(e) => setName(e.target.value)} style={{ marginTop: '5%', width: '100%' }} variant='outlined' size='small' label='Name' />
          <TextField onChange={(e) => setUrllink(e.target.value)} style={{ marginTop: '5%', width: '100%' }} variant='outlined' size='small' label='URL_Link' />
          <TextField onChange={(e) => setUsername(e.target.value)} style={{ marginTop: '5%', width: '100%' }} variant='outlined' size='small' label='Username' />
          <TextField onChange={(e) => setPassword(e.target.value)} style={{ marginTop: '5%', width: '100%' }} variant='outlined' size='small' label='Password' type='Password' />
          <FormControl style={{ marginTop: '5%', width: '100%' }} size='small' variant="outlined" >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">Method</InputLabel>
            <Select
              native
              value={method}
              onChange={(e) => setMethod(e.target.value)}
              labelWidth={labelWidth}
              inputProps={{
                name: 'age',
                id: 'outlined-age-native-simple',
              }}
            >
              <option value="" />
              <option value={'MQTT'}>MQTT</option>
              <option value={'REST'}>REST</option>
            </Select>
          </FormControl>
        </div>
      </div>
      <div style={{ marginTop: '5%', width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
        <Button onClick={props.onclose} variant='outlined' size='small' color='default'>cancel</Button>
        <Button onClick={addMiddleware} variant='outlined' size='small' style={{ marginLeft: '3%', backgroundColor: '#429C46', color: 'white' }}>save</Button>
      </div>
    </div>
  )
}

export default Cards