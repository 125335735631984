import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function PageTitleMultipleButton(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h1" size="sm">
        {props.title}
      </Typography>
      {/* {props.button && (
    
      )} */}
      {props.button ? (
        <ButtonGroup variant="contained">
          {props.button.map(val => {
            return (
              <Button
                // classes={{ root: classes.button }}
                variant="contained"
                size="large"
                onClick={val.onClick}
                color="secondary"
              >
                {val.buttonName}
              </Button>
            );
          })}
        </ButtonGroup>
      ) : null}
    </div>
  );
}
